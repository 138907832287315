<template>
  <div class="service">
    <div class="all-box">
      <div class="service-box">
        <h1>用户协议</h1>
        <div class="container">
          <div class="sevice-reg">
            <h3 class="titel">1.关于</h3>
            <p class="sevicecont">
              "本服务条款是您与上海嗨喵网络科技有限公司"(下称"
              <strong>嗨喵科技</strong>)"之间的协议。嗨喵悦动（下称"
              <strong>本服务</strong>
              "在本服务创建帐号并使用之前，您必须首先同意此条款。"
            </p>
            <p class="sevicecont">
              此条款在必要时将进行修订，且毋须另行通知。修订后的条款一旦在网页上公布即有效代替原来的服务条款。
            </p>
          </div>
          <div class="sevice-reg">
            <h3 class="titel">2.账户</h3>
            <p class="sevicecont">
              您同意并理解您有责任将您与用于获得服务的任何帐号相关的密码保密。您负责对您帐号下发生的所有的行为和内容负责
            </p>
          </div>
          <div class="sevice-reg">
            <h3 class="titel">3.付款</h3>
            <p class="sevicecont">
              您注册本服务或其中某服务，即表示您同意支付其中的所有费用。
            </p>
            <p class="sevicecont">
              嗨喵科技可能要求您提供经我们认可的发卡机构发行的信用卡卡号来激活您的服务，和/或支付服务的任何相关费用。嗨喵科技将从您提供的帐号或信用卡帐号中收取使用费及其他费用。如果授权嗨喵科技通过信用卡收取您的帐号相关费用，即表示您授权嗨喵科技自动从该卡持续扣除帐号相关的所有费用。
            </p>
            <p class="sevicecont">
              除非因为嗨喵科技方面的问题导致本服务无法正常提供，您支付的有关本服务的所有费用均不能退款。
            </p>
          </div>
          <div class="sevice-reg">
            <h3 class="titel">4.内容和版权</h3>
            <p class="sevicecont">
              嗨喵科技对您在本服务提交的内容没有所有权，这些内容的所有权仍然归您所有。但是，如果您将页面设置为公开，就意味着您允许其他人查看和共享您的内容。
            </p>
            <p class="sevicecont">
              您明白嗨喵科技对他人提供的任何内容不在任何方面负责或负有责任，嗨喵科技没有义务预先审查此类内容。但是，嗨喵科技有权随时决定内容是否合适和与本条款相符；如果发现此类内容违反本条款或在其他方面令人反感，嗨喵科技可随时并有绝对酌情权预先审查、转移、拒绝、修改和/或删除内容，而不需事先通知。
            </p>
            <p class="sevicecont">
              本服务的外观设计与专利归嗨喵科技所有。未经嗨喵科技书面许可，您不能复制、拷贝，或者重用任何部分代码和外观设
            </p>
          </div>
          <div class="sevice-reg">
            <h3 class="titel">5.隐私政策</h3>
            <p class="sevicecont">
              使用本服务，即表示您同意嗨喵科技合法收集和使用有关您及您所使用本服务的技术性或诊断性信息。收集到的些信息将用于改进网页的内容，提升我们的服务品质。
            </p>
            <p class="sevicecont">
              嗨喵科技不会将您的信息和内容分享或出售给其他的组织。
            </p>
            <p class="sevicecont">但以下情况除外：</p>
            <p class="sevicecont">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您同意让第三方共享资料；
            </p>
            <p class="sevicecont">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;嗨喵科技需要听从法庭传票、法律命令或遵循法律程序；
            </p>
            <p class="sevicecont">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您违反了本条款。
            </p>
          </div>
          <div class="sevice-reg">
            <h3 class="titel">6.终止</h3>
            <p class="sevicecont">
              您可随时终止您的帐号和/或停用本服务。您在服务终止前支付的任何费用均不能退还（除本服务条款另有明文允许者除外）。
            </p>
            <p class="sevicecont">
              嗨喵科技可随时在某些情况下，不需事先通知，立即终止或暂停您所有或部分帐号和/或本服务之进入。
            </p>
            <p class="sevicecont">此类服务终止的原因包括但不限于：</p>
            <p class="sevicecont">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;违反本服务条款；
            </p>
            <p class="sevicecont">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您要求取消或终止您的帐号；
            </p>
            <p class="sevicecont">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;需要听从法庭传票、法律命令或遵循法律程序；
            </p>
            <p class="sevicecont">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;突发的自然灾害导致的问题；
            </p>
            <p class="sevicecont">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;战争、军事行动、暴动或武装叛乱；
            </p>
            <p class="sevicecont">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您没有支付与本服务相关的费用；
            </p>
            <p class="sevicecont">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;一旦您的帐号被终止，阁下将无法正常使用本服务。此外，您可以选择删除您帐
            </p>
            <p class="sevicecont">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号下的所有信息和内容。
            </p>
          </div>
          <div class="sevice-reg">
            <h3 class="titel">7.免责声明</h3>
            <p class="sevicecont">
              您在使用嗨喵科技的服务过程中，必须遵守中华人民共和国有关的法律和法规，不得因任何非法目的使用大屏幕服务系统，如违反相关国家法律法规政策，嗨喵科技有权立即终止您的账号和活动，相关款项概不退还且不承担任何责任。
            </p>
            <p class="sevicecont">
              您通过嗨喵科技平台发送违法信息而触犯国家法律法规，或伤害第三方名誉利益的，由您与第三方自行解决，我方将不承担任何责任。
            </p>
            <p class="sevicecont">
              您在使用嗨喵科技的过程中需自行承担由于网络信号不稳定等原因，引起的系统无法正常使用等风险，我方不承担任何责任。
            </p>
            <p class="sevicecont">
              若证实系我方产品质量问题，导致系统无法正常使用，可依据我方售后协议进行售后处理。
            </p>
            <p class="sevicecont">
              无论任何情况，我方均不会承担除了购买我方产品费用以外的任何财务赔偿、以及您和第三方产生纠纷的责任。
            </p>
          </div>
          <div class="sevice-reg">
            <p class="sevicecont">最近修订 2020年12月02日。</p>
            <p class="sevicecont">©上海嗨喵网络科技有限公司 保留一切权利。</p>
            <p class="sevicecont">©嗨喵悦动</p>
          </div>
        </div>
      </div>
    </div>
    <myFooter />
  </div>
</template>

<script>
import myFooter from '@/components/myFooter.vue';

export default {
  name: 'contact',
  metaInfo: {
    title: '嗨喵悦动 - 嗨喵悦动登录_嗨喵悦动大屏互动',
    meta: [
      {
        name: 'keywords',
        content:
          '嗨喵悦动,嗨喵悦动大屏互动,嗨喵悦动官网,嗨喵悦动登录,微信墙,微信互动,现场互动,嗨现场',
      },
      {
        name: 'description',
        content:
          '嗨喵悦动是一款免费便捷炫酷的活动大屏幕现场互动软件，适用于企业年会、酒吧、婚礼、商业庆典峰会等活动现场，包括微信上墙送祝福、扫码签到、抽奖投票、摇一摇宏包雨等有趣的大屏互动游戏，让活动更精彩！',
      },
    ],
  },
  components: { myFooter },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.all-box {
  width: 100%;
  height: 100%;
}

.service-box {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #f4f4f4;
}

h1 {
  width: 468px;
  margin: 0 auto;
  overflow: hidden;
  font-size: 30px;
  margin-top: 22.5px;
  font-weight: 400;
  color: #4d5462;
  text-align: center;
  margin-bottom: 22.5px;
}

.container {
  background-color: #fff;
  border: 0.75px solid #ededed;
  border-radius: 3.75px;
  font-size: 10.5px;
  margin-bottom: 75px;
}

.sevice-reg {
  padding: 0 22.5px;
  margin-top: 22.5px;
}

.sevice-reg .titel {
  color: #1c9e48;
  font-size: 12px;
  height: 24px;
}

.sevice-reg .sevicecont {
  color: #5f6064;
  line-height: 22.5px;
}
</style>
